<template>
    <div class="applications-page">
        <h1 class="application-header">{{ $t('application') }}</h1>
        <md-table :value="scholarship" class="application-table">
            <md-table-row
                slot-scope="{ item }"
                :class="{'selected': item.applicationId}"
                slot="md-table-row"
            >
                <md-table-cell v-if="showExclamationIcon" md-label="">
                    <img src="@/assets/images/alert.svg" alt="alert" />
                    <md-tooltip md-direction="bottom">
                        <span v-html="exclamationText"></span>
                    </md-tooltip>
                </md-table-cell>
                <md-table-cell md-label="Type">{{ getScholarshipName(item.scholarshipTypeId) }}</md-table-cell>
                <md-table-cell :md-label="$t('applicationListLastName')">{{ item.lastName }}</md-table-cell>
                <md-table-cell :md-label="$t('applicationListFirstName')">{{ item.firstName }}</md-table-cell>
                <md-table-cell :md-label="$t('applicationListInstitution')">{{ item.institutionname }}</md-table-cell>
                <md-table-cell :md-label="$t('applicationListCreated')">{{ formatDate(item.openingDate) }}</md-table-cell>
                <md-table-cell :md-label="$t('applicationListStatus')">{{ getApplicationStatusName(item.applicationStatusId) }}</md-table-cell>
                <md-table-cell>
                    <router-link
                        to="/profile"
                    >
                        <md-button class="btn-edit">
                            <p>{{ $t('edit') }}</p>
                        </md-button>
                    </router-link>
                </md-table-cell>
            </md-table-row>
        </md-table>
        <div v-if="showAcceptDeclineButtons && showButtons" class="buttons-wrap">
            <button class="btn-status accept" @click="openAcceptDeclineModal(1)">Accepter la proposition</button>
            <button class="btn-status decline" @click="openAcceptDeclineModal(0)">Décliner la proposition</button>
        </div>

        <AcceptDecline
            v-if="showAcceptDeclineModal"
            @close="closeAcceptDeclineModal"
            @confirmStatus="confirmStatus"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AcceptDecline from '@/components/accept-decline';
export default {
    name: "application",
    data: () =>({
        showAcceptDeclineModal: false,
        accepted: '',
        showButtons: true,
        changedApplication: null,
        exclamationText: 'Votre dossier de bourse doit être complété. <br />' +
            'Merci de vérifier la section "Documents <br /> ' +
            'du dossier de bourse" de votre demande, et <br />' +
            'de télécharger les documents manquants ou <br />' +
            ' non conformes',
        grantFiles: {
            id: '',
            campusFranceform: '',
            campusFranceformStatusId: '',
            campusFranceformComment: '',

            embassyform: '',
            embassyformStatusId: '',
            embassyformComment: '',

            passportcopy: '',
            passportcopyStatusId: '',
            passportcopyComment: '',

            admissionletter: '',
            admissionletterStatusId: '',
            admissionletterComment: '',
        },
        hasDeclinedFile: false,
        hasEmptyFile: false
    }),
    components: {
        AcceptDecline
    },
    computed: {
        ...mapGetters('applications',['scholarships', 'projectData']),
        ...mapGetters(['simpleLists']),
        ...mapGetters('auth', ['userData']),
        scholarship() {
            let application = []
            if (this.scholarships) {
                application = this.scholarships.filter(item => item.applicationId)
            }
            if(this.changedApplication) {
                application[0].applicationStatusId = this.changedApplication[0].applicationStatusId
            }
            return application
        },
        showAcceptDeclineButtons() {
            const statusId = this.scholarship[0]?.applicationStatusId
            return statusId === 8
        },
        showExclamationIcon() {
            return this.hasDeclinedFile && this.hasEmptyFile && this.scholarship?.applicationStatusId >= 8  || false
        }
    },
    watch: {
        projectData: {
            immediate: true,
            deep: true,
            handler(value) {
                if(value) {
                    const currentData = value
                    for (let field in this.grantFiles) {
                        if (currentData[field] !== "null") {
                            this.grantFiles[field] = currentData[field]
                        }
                    }
                    for(let field in this.grantFiles) {
                        if(field.includes('StatusId')) {
                            this.hasDeclinedFile = true
                        }
                        if(!field.includes('StatusId') && !field.includes('Comment') && field !== 'id' && !this.grantFiles[field]) {
                            this.hasEmptyFile = true
                        }
                    }
                }
            }
        }
    },
    async mounted() {
       await this.getAllScholarships();
       localStorage.setItem('bgfNewData' , JSON.stringify(this.userData))
    },
    methods: {
        ...mapActions('applications',['getAllScholarships']),
        ...mapActions(['getSimpleLists']),
        formatDate(date) {
            return date ? this.$moment(date).format('DD-MM-YYYY') : '-'
        },
        getScholarshipName(id) {
            const item = this.simpleLists?.scholarshipType.find(el => el.id === id)
            return item?.name
        },
        getApplicationStatusName(id) {
            const item = this.simpleLists?.applicationStatus.find(el => el.id === id)
            return item?.name
        },
        openAcceptDeclineModal(accepted) {
            this.accepted = accepted
            this.showAcceptDeclineModal = true
        },
        closeAcceptDeclineModal() {
            this.accepted = ''
            this.showAcceptDeclineModal = false
        },
        async confirmStatus() {
//            await this.$axios
//                .get(process.env.VUE_APP_API_URL + '/AccountApplication/makedecision/', {
//                    params: {
//                        ApplicationId: this.scholarship[0].applicationId,
//                        accepted: this.accepted
//                    }
//                })
//                .then((response) => {
//                    this.showButtons = false
//                    this.changedApplication = [response.data]
//                })
//                .catch((error) => {
//                    console.log(error)
//                })
//            this.closeAcceptDeclineModal()
        }
    },
}
</script>
<style lang="scss">
.md-tooltip {
    color: #000000!important;
    background-color: #FFFFFF!important;
    border: 1px solid #cdd0d9;
    border-radius: 6px;
    padding: 15px;
    width: 400px!important;
    height: auto!important;
    font-size: 16px;
    line-height: 24px;
}
.applications-page {
    .md-table {
        margin-top: 20px;
        box-shadow: unset !important;
    }
    .md-table + .md-table {
        margin-top: 16px
    }
    .md-ripple, .md-table-cell-container {
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: #000000;
        text-align: left;
    }
    .md-table-head {
        border-bottom: 1px solid #000000;

    }
    .md-table-head-label {
        font-weight: bold;
    }
    .md-table-cell {
        &:first-child {
            .md-table-cell-container {
                padding: 0 0 0 5px!important;

                img {
                    cursor: pointer;
                }
            }
        }
    }
}
</style>

<style lang="scss" scoped>
    a:not(.md-button):hover {
        text-decoration: none;
    }

    .applications-page {
        width: 100%;
    }
    .application-header {
        font-weight: 900;
        font-size: 36px;
        line-height: 52px;
        margin-bottom: 25px;
    }

    .application-table {
        width: 100% !important;
        border-bottom: 1px solid #b3b3b3;
    }

    .btn-edit {
        width: 80px;
        height: 36px;
        background: #FFFFFF;
        border: 1px solid #CDD0D9;
        border-radius: 6px;
        cursor: pointer;

        p {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            color: black!important;
            text-transform: capitalize;
        }
    }
    .buttons-wrap {
        margin-top: 20px;

        .btn-status {
            width: 150px;
            height: 36px;
            border-radius: 6px;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 12px;
            line-height: 18px;
            border: none;
            outline: none;
            cursor: pointer;
        }
        .accept {
            background: #0045FF;
            margin-right: 35px;
        }

        .decline {
            background: #FF4646;
        }
    }
</style>