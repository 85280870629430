<template>
    <div class="accept-decline-modal-wrapper">
        <div class="accept-decline-modal">
            <div class="close">
                <img
                    src="@/assets/images/close-icon.svg"
                    alt="close"
                    @click="$emit('close')"
                />
            </div>

            <div>
                <p class="modal-text">{{ $t('applicationAcceptDeclineMessage') }}</p>
                <div class="modal-btn" @click="$emit('confirmStatus')">OK</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'accept-decline',
}
</script>

<style lang="scss" scoped>
a:not(.md-button):hover {
    text-decoration: none;
}
.md-theme-default a:not(.md-button) {
    color: #FFFFFF!important;
}
.accept-decline-modal-wrapper{
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    .accept-decline-modal {
        background: #FFFFFF;
        max-width: 500px;
        padding: 30px 30px 50px 50px;
    }

    .close {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 30px;

        img {
            cursor: pointer;
        }
    }

    .modal-text {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }

    .modal-btn {
        width: 85px;
        height: 48px;
        margin: 25px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0045FF;
        color: #FFFFFF;
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        border-radius: 6px;
        cursor: pointer;
    }
}
</style>